import React from "react"

import { graphql, useStaticQuery } from "gatsby"
import { getSrc } from "gatsby-plugin-image"
import { FileNode } from "gatsby-plugin-image/dist/src/components/hooks"

import { Heading, ScrollButton } from "~/components/ui"

import css from "./storyHeader.module.scss"

interface PropTypes {
  title: string
  buttonLabel: string
}

function StoryHeader({ title, buttonLabel }: PropTypes) {
  const { file } = useStaticQuery<{ file: FileNode }>(graphql`
    query {
      file(relativePath: { eq: "Story/storybg.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 1920, placeholder: BLURRED)
        }
      }
    }
  `)

  const imageSrc = getSrc(file)

  return (
    <div
      className={css.header}
      style={{
        backgroundImage: `url(${imageSrc})`,
      }}
    >
      <div className={css.content}>
        <Heading
          className={css.title}
          variant="extraLarge"
          align="center"
          color="white"
          uppercase
        >
          {title}
        </Heading>
        <ScrollButton
          className={css.button}
          to="firstStory"
          color="white"
          offset={-200}
        >
          {buttonLabel}
        </ScrollButton>
      </div>
    </div>
  )
}

export default StoryHeader
